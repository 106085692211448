<template>
     <st-page :title="$t('DOCUMENT_SERIES_TYPE.TITLE')">
        <loader v-if="isLoading" />
        <template #toolbar>
            <document-series-type-list-toolbar />
        </template>
        <document-series-type-list-filter ref="documentSeriesTypeListFilter">
        <document-series-type-list-table @update="updateList"/>
        </document-series-type-list-filter>
    </st-page>
</template>

<script>
import { mapGetters } from "vuex";
import DocumentSeriesTypeListToolbar from "@/modules/document-series-type/components/DocumentSeriesTypeListToolbar.vue";
import DocumentSeriesTypeListTable from "@/modules/document-series-type/components/DocumentSeriesTypeListTable.vue";
import DocumentSeriesTypeListFilter from "@/modules/document-series-type/components/DocumentSeriesTypeListFilter.vue";

export default {
    name: "DocumentSeriesTypeList",
    components: {
        DocumentSeriesTypeListToolbar,
        DocumentSeriesTypeListTable,
        DocumentSeriesTypeListFilter
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
        }),
        loadingFetch() {
            return this.loading['documentSeriesType/get'];
        },
        isLoading() {
            return this.loadingFetch;
        },
    },
    methods: {
        updateList() {
            this.$refs.documentSeriesTypeListFilter.refresh();
        },
    },
};
</script>
