<template>
    <st-filters-pagination
        stateModule="documentSeriesType/list"
        :filters="filters"
        :total="total"
        @change="doFilter"
        :header="$t('DOCUMENT_SERIES_TYPE.LIST.TITLE')"
        :moreFilters="false"
    >
        <template #filters-toolbar>
            <slot name="toolbar"></slot>
        </template>
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { DocumentSeriesTypeModel } from '@/modules/document-series-type/document-series-type-model';

    const { fields } = DocumentSeriesTypeModel;

    const filterSchema = new FilterSchema([
        fields.name,
    ]);

    export default {
        name: 'DocumentSeriesTypeListFilter',
        data() {
            return {
                filters: filterSchema.fields,
                currentPage: 1,
            };
        },
        computed: {
            ...mapGetters({
               total: 'documentSeriesType/list/total',
            }),
        },
        methods: {
            ...mapActions({
                doFetch: 'documentSeriesType/list/doFetch',
            }),
            refresh() {
                this.doFilter(this.params);
            },
            async doFilter(params) {
                this.params = params;
                this.doFetch(params);
            },
        },

    };
</script>
